@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import 'bootstrap/dist/css/bootstrap.min.css';
@import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
}
a {
  text-decoration: none !important;
}
.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

.css-1ycxt2a-MuiButtonBase-root-MuiIconButton-root:hover {
  margin: auto;
  background-color: rgba(255, 255, 255, 0.08);
}

.css-poa6bm-MuiButtonBase-root-MuiIconButton-root:hover {
  margin: auto;
  background-color: rgba(0, 0, 0, 0.04);
}

img.blog-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

a.table-icon {
  margin-right: 5px;
  text-decoration: none;
  color: inherit;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-detail-banner {
  margin: 0 auto 54px;
  text-align: center;
  max-width: 600px;
  border-radius: 15px !important;
  overflow: hidden;
}

img.blog-heading-img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.blog-details-main-all {
  display: flex;
  justify-content: center;
}

.blog-details-main-all .blog-detail-main {
  width: 70%;
}

.card-img {
  width: 100% !important;
  height: 100%;
  object-fit: cover !important;
  min-height: 180px !important;
  max-height: 182px !important;
  transform: scale(1);
  transform-origin: center;
  transition: 0.3s all;
}

h1.blog-title {
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: var(--primary);
  max-width: 664px;
  margin: 0px auto 41px;
  letter-spacing: 0.003em;
  font-weight: 500;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.spacing {
  padding-top: 132px;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.sticky-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.cyber-logo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

img.cyber-logo-img {
  width: 100%;
  height: 50px;
}

.global-search {
  margin-left: 100px;
  margin-top: 7px;
  border: 2px solid #00000070;
  border-radius: 5px;
  height: 39px;
}

.cyber-nav-menu {
  justify-content: center;
  padding-right: 70px;
}

li.list-item {
  list-style: none;
  margin-top: 15px;
}

.blog-card-main {
  padding: 0px 10px 40px;
}

.blog-card-section {
  padding: 70px 0px 10px 25px;
}

.card-img:hover {
  transform: scale(1.1) !important;
}

.cstm-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cyber-blog-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 98%;
  padding: 0px 16px;
}

a.cyber-nav-links {
  padding: 8px 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--primary) !important;
  transition: 0.3s all;
  text-decoration: none;
}

.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.banner:hover img {
  transform: scale(1.1);
}

.banner-title {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 110px;
  color: #ffc107;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.5s ease;
  /* Add opacity transition */
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  color: #00FF00;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.5s ease;
  /* Add opacity transition */
}

.short-desc {
  margin: 10px 0 0 0 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--primary);
  min-height: 45px;
  max-height: 50px;
}

.blog-detail-main {
  padding: 20px 0px 45px;
}

.ai-loading-sapcing {
  min-height: 100vh;
}

.blog-detail-label {
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  color: var(--blue250);
  background: rgba(132, 139, 171, 0.12);
  border-radius: 8px;
  padding: 8px 16px;
  margin: 0px auto 20px;
  width: -moz-fit-content;
  width: fit-content;
}

section.container {
  border: dashed;
  border-radius: 5px;
}

.rdw-editor-main {
  background: transparent;
  border-radius: 10px;
  min-height: 300px;
  overflow: auto;
  box-sizing: border-box;
  max-height: 500px;
  border: solid #c4c4c4;
}

.blog-html-content-sec {
  padding: 40px 0 80px;
  color: inherit;
}

.blog-html-content .blog-h4 {
  color: var(--primary);
}

.admin-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps-sidebar-container.css-dip3t8 {
  background: inherit;
}

.css-1wvake5 {
  border-right-style: none !important;
}

.blog-html-content h1,
.blog-html-content h2,
.blog-html-content h3,
.blog-html-content h4,
.blog-html-content h5,
.blog-html-content h6,
.blog-html-content p,
.blog-html-content strong,
.blog-html-content li,
.blog-html-content a,
.blog-html-content span {
  color: var(--primary);
  font-family: "Nunito Sans";
}

.blog-html-content p {
  font-size: 16px !important;
  font-weight: 500;
}

.blog-html-content p strong {
  margin-bottom: 8px;
}

.blog-html-content img {
  margin: 24px 0;
  height: auto !important;
  border-radius: 15px;
  width: 100% !important;
}

.blog-html-content li {
  list-style: disc;
  margin-bottom: 4px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .sticky-header {
    padding: 5px;
    /* Adjust padding for smaller screens */
    font-size: 14px;
    /* Adjust font size for smaller screens */
  }
}

/* Additional Media Queries for other screen sizes if needed */
@media (max-width: 480px) {
  .sticky-header {
    font-size: 12px;
    /* Adjust font size for even smaller screens */
  }
}

@media (max-width: 768px) {
  .banner-title {
    font-size: 60px;
    top: 30%;
  }

  .banner-text {
    font-size: 40px;
    top: 45%;
  }
}

@media (min-width: 1200px) {

  .h1,
  h1 {
    font-size: 2.5rem;
  }
}